.home__container{
  row-gap: 7rem;
}
.home__content{
    grid-template-columns: 118px repeat(2, 1fr);
    padding-top: 5.5rem;
    column-gap: 2rem;
    align-items: center;
}
.home__social{
    display: grid;
    grid-template-columns: max-content;
    row-gap: 1rem;
}
.home__social-icon{
    font-size: 1.25rem;
    color: var(--title-color);
}
.home__social-icon:hover{
    color: var(--title-color-dark);

}
.home__title{
    font-size: var(--custom);
    margin-bottom: var(--mb-0-25);
}
.home__subtitle{
    position: relative;
    font-size: var(--h3-font-size);
    padding-left: 4.4rem;
    font-weight: var(--font-normal);
    margin-bottom: var(--mb-1)
}
.home__subtitle::before{
    content: "";
    position: absolute;
    width: 70px;
    height: 2px;
    background-color:  var(--text-color);
    left: 0;
    top: 1rem;
}
.home__description{
    max-width: 400px;
    margin-bottom: var(--mb-3);
    text-align: justify;
    font-size: medium;
}
.home__img{  
    background: url(../../assets//Faz.jpg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    width: 300px;
    box-shadow: inset 0 0 0 3px rgba(49, 48, 48, 0.3);
    order: 1;
    justify-self: center;
    height: 370px;
    border-radius: 10px;
    
}
@keyframes profile__animate{
    0%{
        border-radius: 60% 40% 30% 70%/60% 30% 70% 40%;
    }
    50%{
        border-radius: 30% 60% 70% 40%/50% 60% 30% 60%;
    }
    100%{
        border-radius: 60% 40% 30% 70%/60% 30% 70% 40%;
    }
}
.home__scroll{
    margin-left: 9.25rem;
}

.wheel{
    animation: scroll 2s ease infinite;
}
@keyframes scroll{
    0%{
        transform: translateY(0);
    }
    30%{
        transform: translateY(3.75rem);

    }
}
.home__scroll-name{
    color: var(--title-color);
    font-weight: var(--font-medium);
    margin-right: var(--mb-0-25);
    margin-left: 2px;
}
.home__scroll-arrow{
    font-size: 1.25rem;
    color: var(--title-color);
}
@media screen and (max-width: 928px){
 
  .home__content{
    grid-template-columns: 100px repeat(2, 1fr);
    column-gap: 2.38rem;
  }
  .home__subtitle{
    padding-left: 3.75rem;
    margin-bottom: var(--mb-1);
  }
  .home__subtitle::before{
    width: 42px;
    top: 0.8rem;
  }
  .home__description{
    max-width: initial;
    margin-bottom: var(--mb-2-5);
  }
  .home__img{
    width: 250px;
    height: 270px;
    box-shadow: inset 0 0 0 8px rgb(225 225 225/ 30%);

  }
  .home__scroll{
    margin-left: 7.5rem;
  }

  }
  @media screen and (max-width: 768px){
    .home__content{
        grid-template-columns: 0.5fr 3fr;
        padding-top: 3.5rem;
    }
    .home__social{
      margin-left: 0.4rem;
    }
    .home__img{
        order: initial;
        justify-self: initial;
      }
      .home__data{
        grid-column: 1/3;
        margin-left: 1.75rem;
      }
      .home__img{
        box-shadow: inset 0 0 0 6px rgb(255 255 255 /30%);
        width: 200px;
        height: 230px;
      }
      .home__scroll{
        display: none;
      }
      .home__title{
        font-size: var(--h1-font-size);
        margin-top: 0.3rem;
      }
      .home__subtitle{
        font-size: medium;
        margin-top: 1.2rem;
        
      }
      .home__description{
        justify-content: justify;
      }
  
  }
  
  @media screen and (max-width: 350px){
    .home__img{
        width: 185px;
        height: 225px;
    }
  
  }