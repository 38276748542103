.footer{
    background-color: var(--container-color);
    border-top: 1px solid rgba(0, 0, 0, 0.1);
}
.footer__container{
    padding: 2rem 0 1rem;

}
.personal_logo{
    display: flex;
    justify-content: center;
}
.personal_logo img{
    width: 15%;
}
.footer__title,
.footer__link{
    color: var(--title-color);
}
.footer__title{
    text-align: center;
    margin-bottom: var(--mb-2);
}
.footer__link:hover{
    color: var(--title-color-dark);
}
.footer__list{
    display: flex;
    justify-content: center;
    column-gap: 1.5rem;
    margin-bottom: var(--mb-1);
}
.footer__social{
    display: flex;
    justify-content: center;
    column-gap: 1.25rem;
}
.footer__social-link{
 
    color: black;
    font-size: 1.25rem;
    padding: 0.5rem;
    border-radius: 0.5rem;
    display: inline-flex;
}

.footer__copy{
    display: block;
    margin-top: 3.5rem;
    color: var(--title-color);
    text-align: center;
    font-size: var(--smaller-font-size);
}
@media screen and (max-width: 992px){
    .footer__social-link{
        padding: 0.25rem;
        border-radius: 0.25rem;
        font-size: 1rem;
    }
}
@media screen and (max-width: 768px){
    .personal_logo img{
        width: 55%;
    }
    .footer__title{
        font-size: var(--custom);
    }
}
@media screen and (max-width: 576px){


}
@media screen and (max-width: 376px){
.footer__container{
    padding: 1rem;
}
.footer__link{
    font-size: var(--smaller-font-size);
}
}