.skills__container{
    grid-template-columns: repeat(3, 350px);
    column-gap: 3rem;
    justify-content: center;
}
.skills__content{
    background-color:var(--container-color);
    border: 1px solid rgb(0, 0, 0, 0.1);
    padding: 2rem 4rem;
    border-radius: 1.25rem;
}
.skills__title{
    font-size: var(--normal-font-size);
    font-weight: var(--font-semi-bold);
    text-align: center;
    margin-bottom: var(--mb-2);

}
.skills__box{
    display: flex;
    justify-content: center;
    column-gap: 2.9rem;
}
.skills__group{
    display: grid;
    align-items: center;
    row-gap: 1.2rem;
}
.skills__data{
    display: flex;
    column-gap: 1rem;
    width: 230%;
}
.skills .bx-badge-check{
    font-size: 0.7rem;
    color: var(--title-color);
}
.skills__name{
    font-size: var(--normal-font-size);
    font-weight: var(--font-medium);
    line-height: 16px;
    
}
.skills__level{
    font-size: var(--tiny-font-size);
}
@media screen and (max-width: 992px){
    .skills__container{
        grid-template-columns: max-content;
        row-gap: 2rem;
    }
  
  }

  @media screen and (max-width: 576px){
    .skills__container{
        grid-template-columns: 1fr;
    }
    .skills__content{
        padding: 1.5rem;
    }
    .skills__data{
        width: 100%;
    }
    .skills__name{
        font-size: 12px;
    }
  }
  @media screen and (max-width: 350px){
    .skills__box{
        column-gap: 1.25rem;
    }
    .skills__name{
        font-size: var(--small-font-size);
    }
  
  }
  
  
  